/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getLocaleLink } from 'i18n';

const RequestContent = (props) => {
  const { title, description, length, id } = props;

  return (
    <div className="request-textblock">
      <p className="request-textblock-title">
        <Link to={getLocaleLink(`/request/${id}`)}>{title}</Link>
      </p>
      {description && (
        <p className="request-textblock-description">
          {length ? `${description.slice(0, length).trim()}...` : description}
        </p>
      )}
    </div>
  );
};

RequestContent.propTypes = {
  description: PropTypes.string.isRequired,
  length: PropTypes.number,
  title: PropTypes.string.isRequired
};

export default RequestContent;
