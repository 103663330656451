/* eslint-disable react/no-multi-comp */
import React, { Suspense, useState, useEffect } from 'react';
import queryString from 'query-string';
import { renderRoutes } from 'react-router-config';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Logo, AppStore, GooglePlay } from 'components/Icon';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLocaleLink } from 'i18n';
import { Locale } from 'components';

const googlePlayLink = process.env.REACT_APP_GOOGLE_PLAY_LINK;
const appleStoreLink = process.env.REACT_APP_APPLE_STORE_LINK;

const Page = (props) => {
  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);
  const {
    route,
    location: { search }
  } = props;

  const params = queryString.parse(search);
  const showHeader = params.app === undefined || params.app !== 'true';
  const location = useLocation();

  useEffect(() => {
    setMenu(false);
  }, [location]);

  const onToggleMenu = (e) => {
    e.preventDefault();
    setMenu(!menu);
  };

  return (
    <main className={`page-container ${!showHeader ? 'no-header' : ''}`}>
      {showHeader && (
        <div className="page-header subtitle2">
          <nav>
            <ul>
              <li className="left-item">
                <Link to="/">
                  <Logo />
                </Link>
              </li>
              <li>
                <Link className="" to={getLocaleLink()}>
                  {t('Map')}
                </Link>
              </li>
              <li>
                <Link to={getLocaleLink('/about')}>{t('About')}</Link>
              </li>
              <li>
                <Link to={getLocaleLink('/faq')}>{t('FAQ')}</Link>
              </li>
              <li>
                <Link to={getLocaleLink('/bonus_point_system')}>
                  {t('Bonus system')}
                </Link>
              </li>
              <li>
                <Link to={getLocaleLink('/agreement')}>{t('Agreement')}</Link>
              </li>
              <li>
                <Link to={getLocaleLink('/policy')}>{t('Privacy policy')}</Link>
              </li>
              <li>
                <Link to={getLocaleLink('/support')}>{t('Support')}</Link>
              </li>
            </ul>
          </nav>

          <Locale />
        </div>
      )}
      {showHeader && (
        <div className="header-mobile">
          <div className="header-mobile__menu">
            <Menu onClick={onToggleMenu} />

            <Logo />
          </div>
        </div>
      )}

      <div className={`mobile-menu ${menu ? 'show' : ''}`}>
        <nav className="mobile-menu__list headline">
          <ul>
            <li>
              <Link className="" to={getLocaleLink()}>
                {t('Map')}
              </Link>
            </li>
            <li>
              <Link to={getLocaleLink('/about')}>{t('About')}</Link>
            </li>
            <li>
              <Link to={getLocaleLink('/faq')}>{t('FAQ')}</Link>
            </li>
            <li>
              <Link to={getLocaleLink('/bonus_point_system')}>
                {t('Bonus system')}
              </Link>
            </li>
            <li>
              <Link to={getLocaleLink('/agreement')}>{t('Agreement')}</Link>
            </li>
            <li>
              <Link to={getLocaleLink('/policy')}>{t('Privacy policy')}</Link>
            </li>
            <li>
              <Link to={getLocaleLink('/support')}>{t('Support')}</Link>
            </li>
          </ul>
        </nav>
        <div className="mobile-menu__footer">
          <Locale />
          <div>
            <a href={appleStoreLink} target="_blank">
              <AppStore />
            </a>
            <a href={googlePlayLink} target="_blank">
              <GooglePlay />
            </a>
          </div>
        </div>
      </div>

      <div className="page-content">
        <Suspense fallback={null}>
          {renderRoutes(route.routes)}
        </Suspense>
      </div>
    </main>
  );
};

Page.propTypes = {
  route: PropTypes.object
};

export default Page;
