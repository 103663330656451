'use strict';
class Status {
  constructor(value) {
    if (value instanceof Status) this._value = value._value;
    else this._value = value;
    if (typeof this._value === 'string')
      this._value = this.constructor.mask(this._value);
  }
  isArchived() {
    return (this._value & 1) !== 0;
  }
  get value() {
    return this._value;
  }
  toJSON() {
    return this._value;
  }
  toString() {
    const result = [];
    (this.constructor.constList || []).forEach((_const) => {
      if (this[`is${_const}`]()) result.push(_const);
    });
    return result.join('|');
  }
  any(b) {
    var bits = b;
    if (typeof this._value === 'undefined' || typeof bits === 'undefined')
      return false;
    if (typeof bits === 'string') bits = bits.split('|');
    if (Array.isArray(bits)) {
      bits = bits
        .map((bit) => +this.constructor[bit])
        .reduce((prev, curr) => prev | curr);
    }
    return (this._value & bits) !== 0;
  }
  all(b) {
    var bits = b;
    if (typeof this._value === 'undefined' || typeof bits === 'undefined')
      return false;
    if (typeof bits === 'string') bits = bits.split('|');
    if (Array.isArray(bits))
      bits = bits
        .map((bit) => +this.constructor[bit])
        .reduce((prev, curr) => prev | curr);
    return (this._value & bits) === bits;
  }
  static mask(b = [], negate = false) {
    let bits = b;
    if (typeof bits === 'string') bits = bits.split('|');
    let result = 0;
    bits.forEach((bit) => {
      result |= +this[bit];
    });
    if (!negate) return result;
    else {
      return (
        (this.constList || [])
          .map((bit) => +this[bit])
          .reduce((prev, curr) => prev | curr) - result
      );
    }
  }
}
class ACTStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isRead(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
}
ACTStatus.Archived = 1;
ACTStatus.Removed = 2;
ACTStatus.Read = 4;
ACTStatus.constList = ['Archived', 'Removed', 'Read'];
ACTStatus.prefix = 'ACT';
window['ACTStatus'] = ACTStatus;
class BUSStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
BUSStatus.Archived = 1;
BUSStatus.Removed = 2;
BUSStatus.constList = ['Archived', 'Removed'];
BUSStatus.prefix = 'BUS';
window['BUSStatus'] = BUSStatus;
class CHTStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isMultiply(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
}
CHTStatus.Archived = 1;
CHTStatus.Removed = 2;
CHTStatus.Multiply = 4;
CHTStatus.constList = ['Archived', 'Removed', 'Multiply'];
CHTStatus.prefix = 'CHT';
window['CHTStatus'] = CHTStatus;
class CLStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isAuthenticated(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isOnline(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isCharity(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isTrusted(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isConfirmedSMS(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isWantBeCharity(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isCharityRequestDecline(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isRegistrationCompleted(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
  isVerified(v) {
    v === undefined && (v = this._value);
    return (v & 1024) !== 0;
  }
  isDeveloper(v) {
    v === undefined && (v = this._value);
    return (v & 2048) !== 0;
  }
}
CLStatus.Archived = 1;
CLStatus.Removed = 2;
CLStatus.Authenticated = 4;
CLStatus.Online = 8;
CLStatus.Charity = 16;
CLStatus.Trusted = 32;
CLStatus.ConfirmedSMS = 64;
CLStatus.WantBeCharity = 128;
CLStatus.CharityRequestDecline = 256;
CLStatus.RegistrationCompleted = 512;
CLStatus.Verified = 1024;
CLStatus.Developer = 2048;
CLStatus.constList = [
  'Archived',
  'Removed',
  'Authenticated',
  'Online',
  'Charity',
  'Trusted',
  'ConfirmedSMS',
  'WantBeCharity',
  'CharityRequestDecline',
  'RegistrationCompleted',
  'Verified',
  'Developer'
];
CLStatus.prefix = 'CL';
window['CLStatus'] = CLStatus;
class CMTStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isRead(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
}
CMTStatus.Archived = 1;
CMTStatus.Removed = 2;
CMTStatus.Read = 4;
CMTStatus.constList = ['Archived', 'Removed', 'Read'];
CMTStatus.prefix = 'CMT';
window['CMTStatus'] = CMTStatus;
class FILEStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isDirectory(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isEmpty(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isProcessing(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isFinished(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
}
FILEStatus.Archived = 1;
FILEStatus.Removed = 2;
FILEStatus.Directory = 4;
FILEStatus.Empty = 8;
FILEStatus.Processing = 16;
FILEStatus.Finished = 32;
FILEStatus.constList = [
  'Archived',
  'Removed',
  'Directory',
  'Empty',
  'Processing',
  'Finished'
];
FILEStatus.prefix = 'FILE';
window['FILEStatus'] = FILEStatus;
class I18NStatus extends Status {
  isDirectory(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isMarkdown(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
}
I18NStatus.Archived = 1;
I18NStatus.Directory = 2;
I18NStatus.Markdown = 4;
I18NStatus.Removed = 8;
I18NStatus.constList = ['Archived', 'Directory', 'Markdown', 'Removed'];
I18NStatus.prefix = 'I18N';
window['I18NStatus'] = I18NStatus;
class MSGStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isRead(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
}
MSGStatus.Archived = 1;
MSGStatus.Removed = 2;
MSGStatus.Read = 4;
MSGStatus.constList = ['Archived', 'Removed', 'Read'];
MSGStatus.prefix = 'MSG';
window['MSGStatus'] = MSGStatus;
class NFStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
NFStatus.Archived = 1;
NFStatus.Removed = 2;
NFStatus.constList = ['Archived', 'Removed'];
NFStatus.prefix = 'NF';
window['NFStatus'] = NFStatus;
class ODINStatus extends Status {}
ODINStatus.Archived = 1;
ODINStatus.constList = ['Archived'];
ODINStatus.prefix = 'ODIN';
window['ODINStatus'] = ODINStatus;
class PLCStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
PLCStatus.Archived = 1;
PLCStatus.Removed = 2;
PLCStatus.constList = ['Archived', 'Removed'];
PLCStatus.prefix = 'PLC';
window['PLCStatus'] = PLCStatus;
class PREStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
PREStatus.Archived = 1;
PREStatus.Removed = 2;
PREStatus.constList = ['Archived', 'Removed'];
PREStatus.prefix = 'PRE';
window['PREStatus'] = PREStatus;
class PRJStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
PRJStatus.Archived = 1;
PRJStatus.Removed = 2;
PRJStatus.constList = ['Archived', 'Removed'];
PRJStatus.prefix = 'PRJ';
window['PRJStatus'] = PRJStatus;
class RQStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isClosed(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isFundraising(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isPrivate(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isHasReport(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isRepost(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
}
RQStatus.Archived = 1;
RQStatus.Removed = 2;
RQStatus.Closed = 4;
RQStatus.Fundraising = 8;
RQStatus.Private = 16;
RQStatus.HasReport = 32;
RQStatus.Repost = 64;
RQStatus.constList = [
  'Archived',
  'Removed',
  'Closed',
  'Fundraising',
  'Private',
  'HasReport',
  'Repost'
];
RQStatus.prefix = 'RQ';
window['RQStatus'] = RQStatus;
class RSStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
RSStatus.Archived = 1;
RSStatus.Removed = 2;
RSStatus.constList = ['Archived', 'Removed'];
RSStatus.prefix = 'RS';
window['RSStatus'] = RSStatus;
class RSPStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isCanceled(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isClosed(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isHelped(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isWithDonation(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
}
RSPStatus.Archived = 1;
RSPStatus.Removed = 2;
RSPStatus.Canceled = 4;
RSPStatus.Closed = 8;
RSPStatus.Helped = 16;
RSPStatus.WithDonation = 32;
RSPStatus.constList = [
  'Archived',
  'Removed',
  'Canceled',
  'Closed',
  'Helped',
  'WithDonation'
];
RSPStatus.prefix = 'RSP';
window['RSPStatus'] = RSPStatus;
class SCDStatus extends Status {
  isPeriodic(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isFinished(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isKilled(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isPending(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
}
SCDStatus.Archived = 1;
SCDStatus.Periodic = 2;
SCDStatus.Finished = 4;
SCDStatus.Killed = 8;
SCDStatus.Pending = 16;
SCDStatus.Removed = 32;
SCDStatus.constList = [
  'Archived',
  'Periodic',
  'Finished',
  'Killed',
  'Pending',
  'Removed'
];
SCDStatus.prefix = 'SCD';
window['SCDStatus'] = SCDStatus;
class SCHStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
SCHStatus.Archived = 1;
SCHStatus.Removed = 2;
SCHStatus.constList = ['Archived', 'Removed'];
SCHStatus.prefix = 'SCH';
window['SCHStatus'] = SCHStatus;
class SSNStatus extends Status {
  isClosed(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isConnected(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
}
SSNStatus.Archived = 1;
SSNStatus.Closed = 2;
SSNStatus.Removed = 4;
SSNStatus.Connected = 8;
SSNStatus.constList = ['Archived', 'Closed', 'Removed', 'Connected'];
SSNStatus.prefix = 'SSN';
window['SSNStatus'] = SSNStatus;
class SBNStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
SBNStatus.Archived = 1;
SBNStatus.Removed = 2;
SBNStatus.constList = ['Archived', 'Removed'];
SBNStatus.prefix = 'SBN';
window['SBNStatus'] = SBNStatus;
class TGStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
TGStatus.Archived = 1;
TGStatus.Removed = 2;
TGStatus.constList = ['Archived', 'Removed'];
TGStatus.prefix = 'TG';
window['TGStatus'] = TGStatus;
class TRStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isFirst(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isAccepted(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isSecured(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isDeclined(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isRejected(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
  isFailed(v) {
    v === undefined && (v = this._value);
    return (v & 128) !== 0;
  }
  isAcceptedLocally(v) {
    v === undefined && (v = this._value);
    return (v & 256) !== 0;
  }
  isWaitingForLocalConfirmation(v) {
    v === undefined && (v = this._value);
    return (v & 512) !== 0;
  }
  isWaitingForRemoteConfirmation(v) {
    v === undefined && (v = this._value);
    return (v & 1024) !== 0;
  }
  isWaitingForPaymentInfo(v) {
    v === undefined && (v = this._value);
    return (v & 2048) !== 0;
  }
}
TRStatus.Archived = 1;
TRStatus.Removed = 2;
TRStatus.First = 4;
TRStatus.Accepted = 8;
TRStatus.Secured = 16;
TRStatus.Declined = 32;
TRStatus.Rejected = 64;
TRStatus.Failed = 128;
TRStatus.AcceptedLocally = 256;
TRStatus.WaitingForLocalConfirmation = 512;
TRStatus.WaitingForRemoteConfirmation = 1024;
TRStatus.WaitingForPaymentInfo = 2048;
TRStatus.constList = [
  'Archived',
  'Removed',
  'First',
  'Accepted',
  'Secured',
  'Declined',
  'Rejected',
  'Failed',
  'AcceptedLocally',
  'WaitingForLocalConfirmation',
  'WaitingForRemoteConfirmation',
  'WaitingForPaymentInfo'
];
TRStatus.prefix = 'TR';
window['TRStatus'] = TRStatus;
class USRStatus extends Status {
  isOnline(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
  isBanned(v) {
    v === undefined && (v = this._value);
    return (v & 4) !== 0;
  }
  isFired(v) {
    v === undefined && (v = this._value);
    return (v & 8) !== 0;
  }
  isInternal(v) {
    v === undefined && (v = this._value);
    return (v & 16) !== 0;
  }
  isAuthenticated(v) {
    v === undefined && (v = this._value);
    return (v & 32) !== 0;
  }
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 64) !== 0;
  }
}
USRStatus.Archived = 1;
USRStatus.Online = 2;
USRStatus.Banned = 4;
USRStatus.Fired = 8;
USRStatus.Internal = 16;
USRStatus.Authenticated = 32;
USRStatus.Removed = 64;
USRStatus.constList = [
  'Archived',
  'Online',
  'Banned',
  'Fired',
  'Internal',
  'Authenticated',
  'Removed'
];
USRStatus.prefix = 'USR';
window['USRStatus'] = USRStatus;
class USGStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
USGStatus.Archived = 1;
USGStatus.Removed = 2;
USGStatus.constList = ['Archived', 'Removed'];
USGStatus.prefix = 'USG';
window['USGStatus'] = USGStatus;
class UTLStatus extends Status {
  isRemoved(v) {
    v === undefined && (v = this._value);
    return (v & 2) !== 0;
  }
}
UTLStatus.Archived = 1;
UTLStatus.Removed = 2;
UTLStatus.constList = ['Archived', 'Removed'];
UTLStatus.prefix = 'UTL';
window['UTLStatus'] = UTLStatus;
window.kv = {
  customCldrData: {
    main: {
      root: {
        numbers: {
          currencies: {
            XBT: {
              displayName: 'Bitcoin',
              'displayName-count-one': 'bitcoin',
              'displayName-count-other': 'bitcoins',
              symbol: 'XBT',
              'symbol-alt-narrow': 'XBT'
            }
          }
        }
      },
      ru: {
        numbers: {
          currencies: {
            XBT: {
              displayName: 'Биткоин',
              'displayName-count-one': 'биткоин',
              'displayName-count-few': 'биткоина',
              'displayName-count-many': 'биткоинов',
              'displayName-count-other': 'биткоина',
              symbol: 'XBT',
              'symbol-alt-narrow': 'XBT'
            }
          }
        }
      }
    }
  },
  prefixMap: {
    SCH: 'search',
    USR: 'user',
    UTL: 'utils',
    FILE: 'file',
    BUS: 'bus',
    I18N: 'i18n',
    PLC: 'policy',
    USG: 'user_group',
    RS: 'resource',
    SCD: 'scheduler',
    NF: 'notification',
    SSN: 'session',
    CL: 'client',
    TG: 'tag',
    RQ: 'request',
    CMT: 'comment',
    RSP: 'response',
    SBN: 'subscription',
    MSG: 'message',
    CHT: 'chat',
    ACT: 'activity',
    PRE: 'pretension',
    TR: 'transaction',
    PRJ: 'project'
  },
  uploadsBasePath: 'https://bh.nsolid.ru',
  locales: [
    'ar',
    'bg',
    'ca',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'es',
    'eu',
    'fa',
    'fi',
    'fr',
    'ga',
    'gl',
    'hi',
    'hu',
    'hy',
    'id',
    'it',
    'ja',
    'jv',
    'ko',
    'ku',
    'lt',
    'lv',
    'nl',
    'no',
    'pt',
    'ro',
    'ru',
    'sv',
    'th',
    'tr',
    'zh'
  ],
  denominationCoef: { XBT: 1000000000, FUN: 100, '*': 1000 },
  currencies: [
    'AED',
    'AFN',
    'ALL',
    'AMD',
    'ANG',
    'AOA',
    'ARS',
    'AUD',
    'AWG',
    'AZN',
    'BAM',
    'BBD',
    'BDT',
    'BGN',
    'BHD',
    'BIF',
    'BMD',
    'BND',
    'BOB',
    'BRL',
    'BSD',
    'BTN',
    'BWP',
    'BYN',
    'BZD',
    'CAD',
    'CDF',
    'CHF',
    'CLF',
    'CLP',
    'CNH',
    'CNY',
    'COP',
    'CRC',
    'CUC',
    'CUP',
    'CVE',
    'CZK',
    'DJF',
    'DKK',
    'DOP',
    'DZD',
    'EGP',
    'ERN',
    'ETB',
    'EUR',
    'FJD',
    'FKP',
    'GBP',
    'GEL',
    'GGP',
    'GHS',
    'GIP',
    'GMD',
    'GNF',
    'GTQ',
    'GYD',
    'HKD',
    'HNL',
    'HRK',
    'HTG',
    'HUF',
    'IDR',
    'ILS',
    'IMP',
    'INR',
    'IQD',
    'IRR',
    'ISK',
    'JEP',
    'JMD',
    'JOD',
    'JPY',
    'KES',
    'KGS',
    'KHR',
    'KMF',
    'KPW',
    'KRW',
    'KWD',
    'KYD',
    'KZT',
    'LAK',
    'LBP',
    'LKR',
    'LRD',
    'LSL',
    'LYD',
    'MAD',
    'MDL',
    'MGA',
    'MKD',
    'MMK',
    'MNT',
    'MOP',
    'MRO',
    'MUR',
    'MVR',
    'MWK',
    'MXN',
    'MYR',
    'MZN',
    'NAD',
    'NGN',
    'NIO',
    'NOK',
    'NPR',
    'NZD',
    'OMR',
    'PAB',
    'PEN',
    'PGK',
    'PHP',
    'PKR',
    'PLN',
    'PYG',
    'QAR',
    'RON',
    'RSD',
    'RUB',
    'RWF',
    'SAR',
    'SBD',
    'SCR',
    'SDG',
    'SEK',
    'SGD',
    'SHP',
    'SLL',
    'SOS',
    'SRD',
    'SSP',
    'STD',
    'SVC',
    'SYP',
    'SZL',
    'THB',
    'TJS',
    'TMT',
    'TND',
    'TOP',
    'TRY',
    'TTD',
    'TWD',
    'TZS',
    'UAH',
    'UGX',
    'USD',
    'UYU',
    'UZS',
    'VEF',
    'VND',
    'VUV',
    'WST',
    'XAF',
    'XAG',
    'XAU',
    'XBT',
    'XCD',
    'XDR',
    'XOF',
    'XPD',
    'XPF',
    'XPT',
    'YER',
    'ZAR',
    'ZMW',
    'ZWL'
  ],
  countries: [
    'AU',
    'AT',
    'AZ',
    'AX',
    'AL',
    'DZ',
    'AS',
    'AI',
    'AO',
    'AD',
    'AG',
    'AR',
    'AM',
    'AW',
    'AF',
    'BS',
    'BD',
    'BB',
    'BH',
    'BY',
    'BZ',
    'BE',
    'BJ',
    'BM',
    'BG',
    'BO',
    'BA',
    'BW',
    'BR',
    'IO',
    'BN',
    'BF',
    'BI',
    'BT',
    'VU',
    'VA',
    'GB',
    'HU',
    'VE',
    'VG',
    'VI',
    'TL',
    'VN',
    'GA',
    'HT',
    'GY',
    'GM',
    'GH',
    'GP',
    'GT',
    'GN',
    'GW',
    'DE',
    'GI',
    'HN',
    'HK',
    'GD',
    'GL',
    'GR',
    'GE',
    'GU',
    'DK',
    'DJ',
    'DM',
    'DO',
    'EG',
    'ZM',
    'EH',
    'ZW',
    'IL',
    'IN',
    'ID',
    'JO',
    'IQ',
    'IR',
    'IS',
    'ES',
    'IT',
    'YE',
    'CV',
    'KZ',
    'KY',
    'KH',
    'CM',
    'CA',
    'QA',
    'KE',
    'CY',
    'KG',
    'KI',
    'CN',
    'KP',
    'CC',
    'CO',
    'KM',
    'CG',
    'CD',
    'CR',
    'CI',
    'CU',
    'KW',
    'LA',
    'LV',
    'LS',
    'LR',
    'LB',
    'LY',
    'LT',
    'LI',
    'LU',
    'MU',
    'MR',
    'MG',
    'YT',
    'MO',
    'MK',
    'MW',
    'MY',
    'ML',
    'MV',
    'MT',
    'MA',
    'MQ',
    'MH',
    'MX',
    'MZ',
    'MD',
    'MC',
    'MN',
    'MS',
    'MM',
    'NA',
    'NR',
    'NP',
    'NE',
    'NG',
    'NL',
    'NI',
    'NU',
    'NZ',
    'NC',
    'NO',
    'NF',
    'CX',
    'SH',
    'CK',
    'TC',
    'AE',
    'OM',
    'PK',
    'PW',
    'PS',
    'PA',
    'PG',
    'PY',
    'PE',
    'PL',
    'PT',
    'PR',
    'KR',
    'RE',
    'RU',
    'RW',
    'RO',
    'SV',
    'WS',
    'SM',
    'ST',
    'SA',
    'SZ',
    'MP',
    'SC',
    'PM',
    'SN',
    'VC',
    'KN',
    'LC',
    'RS',
    'SG',
    'SY',
    'SK',
    'SI',
    'US',
    'SB',
    'SO',
    'SD',
    'SR',
    'SL',
    'TJ',
    'TH',
    'TW',
    'TZ',
    'TG',
    'TK',
    'TO',
    'TT',
    'TV',
    'TN',
    'TM',
    'TR',
    'UG',
    'UZ',
    'UA',
    'WF',
    'UY',
    'FO',
    'FM',
    'FJ',
    'PH',
    'FI',
    'FK',
    'FR',
    'GF',
    'PF',
    'HR',
    'CF',
    'TD',
    'ME',
    'CZ',
    'CL',
    'CH',
    'SE',
    'SJ',
    'LK',
    'EC',
    'GQ',
    'ER',
    'EE',
    'ET',
    'ZA',
    'JM',
    'JP'
  ],
  geocodingApiKey: { google: 'AIzaSyCzHIBJuzlvzdOlx1KrdzkZZvffQv2rvps' },
  googleTranslationApiKey: 'AIzaSyA0zDa3QLkoSDEvu6Mx0uL_beCir7Lerwk',
  _endpointToPrefix: {
    searches: 'SCH',
    users: 'USR',
    utils: 'UTL',
    files: 'FILE',
    buses: 'BUS',
    i18n: 'I18N',
    policies: 'PLC',
    user_groups: 'USG',
    resources: 'RS',
    schedulers: 'SCD',
    notifications: 'NF',
    sessions: 'SSN',
    clients: 'CL',
    tags: 'TG',
    requests: 'RQ',
    comments: 'CMT',
    responses: 'RSP',
    subscriptions: 'SBN',
    messages: 'MSG',
    chats: 'CHT',
    activities: 'ACT',
    pretensions: 'PRE',
    transactions: 'TR',
    projects: 'PRJ'
  },
  _prefixToEndpoint: {
    SCH: 'searches',
    USR: 'users',
    UTL: 'utils',
    FILE: 'files',
    BUS: 'buses',
    I18N: 'i18n',
    PLC: 'policies',
    USG: 'user_groups',
    RS: 'resources',
    SCD: 'schedulers',
    NF: 'notifications',
    SSN: 'sessions',
    CL: 'clients',
    TG: 'tags',
    RQ: 'requests',
    CMT: 'comments',
    RSP: 'responses',
    SBN: 'subscriptions',
    MSG: 'messages',
    CHT: 'chats',
    ACT: 'activities',
    PRE: 'pretensions',
    TR: 'transactions',
    PRJ: 'projects'
  },
  prefix_map: {
    SCH: 'search',
    USR: 'user',
    UTL: 'utils',
    FILE: 'file',
    BUS: 'bus',
    I18N: 'i18n',
    PLC: 'policy',
    USG: 'user_group',
    RS: 'resource',
    SCD: 'scheduler',
    NF: 'notification',
    SSN: 'session',
    CL: 'client',
    TG: 'tag',
    RQ: 'request',
    CMT: 'comment',
    RSP: 'response',
    SBN: 'subscription',
    MSG: 'message',
    CHT: 'chat',
    ACT: 'activity',
    PRE: 'pretension',
    TR: 'transaction',
    PRJ: 'project'
  }
};
window.calcDenomination = function (_from, _to) {
  const from =
    window.kv.denominationCoef[_from] || window.kv.denominationCoef['*'];
  const to = window.kv.denominationCoef[_to] || window.kv.denominationCoef['*'];
  return to / from;
};
window.baseToMinor = function (value, currency) {
  const coef =
    window.kv.denominationCoef[currency] || window.kv.denominationCoef['*'];
  return Math.round(value * coef);
};
window.minorToBase = function (value, currency) {
  const coef =
    window.kv.denominationCoef[currency] || window.kv.denominationCoef['*'];
  return value / coef;
};
