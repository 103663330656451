import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './i18n';
import * as statuses from 'utils/statuses';
import { ThemeProvider } from '@material-ui/styles';
import { renderRoutes } from 'react-router-config';

import theme from './theme';
import routes from './routes';
import {
  ScrollReset,
  YandexMetrika,
  // GeoLocation,
  CookiesNotification
} from './components';

import './assets/scss/index.scss';

const history = createBrowserHistory();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <YandexMetrika />
        <ScrollReset />
        {/* <GeoLocation /> */}
        <CookiesNotification />
        {renderRoutes(routes)}
      </Router>
    </ThemeProvider>
  );
};

export default App;
