import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Viber,
  Instagram,
  Facebook,
  VK,
  Twitter,
  Odnoklassniki,
  // Telegram,
  TelegramWeb,
  Whatsapp,
  Link as LinkIcon
} from 'components/Icon';

const Share = (props) => {
  const { className, url, title, description, image } = props;
  const { t } = useTranslation();
  const [copy, setCopy] = useState(false);
  const onClipboard = (e) => {
    e.preventDefault();

    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopy(true);
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  return (
    <div className={className}>
      <ul>
        <li>
          <a href="#" onClick={onClipboard} target="_blank">
            <aIcon />
            {copy ? t('Link copied!') : t('Copy link')}
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=http://localhost:3000/ru/request/RQ-427A0A1"
            target="_blank">
            <Facebook />
            Facebook
          </a>
        </li>
        <li>
          <a href="#" target="_blank">
            <Instagram />
            Direct
          </a>
        </li>
        <li>
          <a href="#" target="_blank">
            <Whatsapp />
            Whatsapp
          </a>
        </li>
        <li>
          {/* https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/web-intent */}
          <a
            href={`https://twitter.com/intent/tweet?text=${title}&url=${url}&via=${process.env.REACT_APP_TWITTER}`}
            target="_blank">
            <Twitter />
            Twitter
          </a>
        </li>
        <li>
          <a
            href={`https://vk.com/share.php?url=${url}&title=${title}&description=${description}&image=${image}&noparse=true`}
            target="_blank">
            <VK />
            VK
          </a>
        </li>
        <li>
          <a href="#" target="_blank">
            <TelegramWeb />
            Telegram
          </a>
        </li>
        <li>
          <a
            href={`https://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st._surl=${url}&st.comments=${description}`}
            target="_blank">
            <Odnoklassniki />
            Одноклассники
          </a>
        </li>
        <li>
          <a href="#">
            <Viber />
            Viber
          </a>
        </li>
      </ul>
    </div>
  );
};

Share.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default Share;
