import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  spacing: 4,
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'p',
        h5: 'p',
        h6: 'p',
        subtitle1: 'p',
        subtitle2: 'p',
        body1: 'p',
        body2: 'p'
      }
    }
  }
});

export default theme;
