import React from 'react';
import PropTypes from 'prop-types';
import { Approved } from 'components/Icon';

const Avatar = (props) => {
  const { src, name, approved } = props;

  const shortName = name
    .trim()
    .split(' ')
    .map((e) => e[0])
    .join('');

  return (
    <div className="avatar">
      <div className="avatar__wrapper">
        {src ? (
          <img src={src} />
        ) : (
          <div className="avatar__named">
            <span>{shortName}</span>
          </div>
        )}
      </div>

      {approved && (
        <span className="avatar__approved">
          <Approved />
        </span>
      )}
    </div>
  );
};

Avatar.propTypes = {
  approved: PropTypes.bool,
  name: PropTypes.string.isRequired,
  // firstName: PropTypes.string.isRequired,
  // lastName: PropTypes.string.isRequired,
  src: PropTypes.string
};

export default Avatar;
