import axios from 'axios';
import normalize from 'utils/normalize';

const proxyUrl = process.env.REACT_APP_PROXY_URL;

export default {
  getTagsList: async () => {
    const {
      data: { data, total }
    } = await axios.post(`${proxyUrl}tags/search/details`, {});

    return {
      total,
      data:
        data.length > 0
          ? data.map((tag) =>
            normalize(tag, {
              _id: '_id',
              urgency: 'urgency',
              category: 'category',
              // title: 'i18n_id.0.value.ru',
              title: {
                en: 'i18n_id.0.value.root',
                ru: 'i18n_id.0.value.ru'
              },
              image: 'img_id.0.url',
              small_image: 'img_id.0.small_img_url'
            })
          )
          : []
      // data.length > 0
      //   ? data.map(d => ({
      //       _id: d._id,
      //       urgency: d.urgency,
      //       category: d.category,
      //       title: d.i18n_id[0].value.ru
      //       // description: d.parent_id.i18n_id[0].value.ru
      //     }))
      //   : []
    };
  }
};
