/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { Time, Geo } from 'components/Icon';

const RequestSpecialInfo = (props) => {
  const { date, address, geo } = props;

  return (
    <div className="request-special-info">
      <p className="request-actual-time">
        {/* <span> */}
        <Time />
        {/* </span> */}
        {new Date(date)
          .toLocaleString('ru', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
          .toString()}
      </p>

      <p className="request-geoposition">
        <span />
        <Geo />
        {address}
      </p>
    </div>
  );
};

RequestSpecialInfo.propTypes = {
  address: PropTypes.string,
  date: PropTypes.string.isRequired,
  geo: PropTypes.object
};

export default RequestSpecialInfo;
