import { createStore, createEvent } from 'effector';

const initialState = {
  lat: 55.755826,
  lng: 37.6173
};

export const setLocation = createEvent('Set location');

export const $location = createStore(initialState).on(
  setLocation,
  (state, { lat, lng }) => ({
    lat,
    lng
  })
);
