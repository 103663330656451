/* eslint-disable no-undef */
import React from 'react';

// import { Approved } from 'components/Icon';
import { Avatar } from 'components';
import PropTypes from 'prop-types';

const RequestHeader = (props) => {
  const { avatar, date, verified, name } = props;

  return (
    <div className="request-header">
      <div className="request-avatar">
        <Avatar
          approved={verified}
          name={name}
          // firstName={firstName}
          // lastName={lastName}
          src={avatar}
        />
      </div>
      <div>
        <p className="request-name">{name}</p>

        <p className="request-date">
          {new Date(date)
            .toLocaleString('ru', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })
            .toString()}
        </p>
      </div>
    </div>
  );
};

RequestHeader.propTypes = {
  avatar: PropTypes.string,
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // firstName: PropTypes.string.isRequired,
  // lastName: PropTypes.string.isRequired,
  verified: PropTypes.bool
};

export default RequestHeader;
