import { path } from 'ramda';

const normalize = (obj, schema) =>
  Object.entries(schema).reduce((acc, [key, p]) => {
    return {
      ...acc,
      [key]: typeof p === 'object' ? normalize(obj, p) : path(p.split('.'), obj)
    };
  }, {});

export default normalize;
