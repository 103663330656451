import React from 'react';
import { ReactComponent as Apple } from 'assets/images/footer/apple.svg';
import { ReactComponent as GooglePlay } from 'assets/images/footer/google-play.svg';
import { Helmet } from 'react-helmet';
import { Box, Grid, Typography } from '@material-ui/core';

import { getAppleAppLink, getGooglePlayAppLink } from 'appRoutes';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  box: {
    margin: '0 auto'
  },
  container: {
    minHeight: '100vh'
  },
  h3: {
    marginBottom: theme.spacing(2)
  },
  link: {
    display: 'inline-block',
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  }
}));

const VkScript = () => {
  return (
    <Helmet>
      <script type="text/javascript">{`!function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://vk.com/js/api/openapi.js?168",t.onload=function(){VK.Retargeting.Init("VK-RTRG-589243-1IHtU"),VK.Retargeting.Hit()},document.head.appendChild(t)}();`}</script>
      <noscript>
        {`<img
          src="https://vk.com/rtrg?p=VK-RTRG-589243-1IHtU"
          style="position:fixed; left:-999px;"
          alt=""
        />`}
      </noscript>
    </Helmet>
  );
};

const InstallApp = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.container}>
      <Grid item xs={12}>
        <VkScript />
        <Typography variant="h3" align="center" className={classes.h3}>
          Установите приложение
        </Typography>
        {/* <Typography variant='h3' className="footnote">Установите приложение</Typography> */}
        <Box display="flex" flexDirection="column" justifyContent="center">
          {/* <Box m={1}> */}
          <a target="_blank" href={getAppleAppLink()} className={classes.link}>
            <Apple />
          </a>
          {/* </Box> */}
          {/* <Box m={1}> */}
          <a
            target="_blank"
            href={getGooglePlayAppLink()}
            className={classes.link}>
            <GooglePlay />
          </a>
          {/* </Box> */}
        </Box>
      </Grid>
    </Grid>
  );
};

export default InstallApp;
