import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const appbg = '#F5F6F7';

const black87 = '#241D35';
const black54 = '#798391';
const black38 = '#969FAB';
const black12 = '#E4E7EB';

const mainColor = '#43A047';

export default {
  black,
  white,
  black12,
  black38,
  black54,
  black87,
  primary: {
    contrastText: white,
    dark: colors.green[900],
    // main: colors.green[500],
    main: mainColor,
    light: colors.green[100]
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400']
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: black87,
    secondary: black54,
    link: black38
  },
  // link: colors.blue[800],
  link: black38,
  border: black12,
  button: black54,
  icon: colors.blueGrey[600],
  background: {
    default: appbg,
    paper: white
  },
  divider: colors.grey[200]
};
