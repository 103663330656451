import React from 'react';

const VideoView = (props) => {
  const {
    data: { src },
    getStyles,
    isFullscreen,
    isModal
  } = props;

  return (
    <div
      className={`react-images__view ${
        isFullscreen ? 'react-images__view--isFullscreen' : ''
      } ${isModal ? 'react-images__view--isModal' : ''}`}
      style={getStyles('view', props)}
    >
      <video
        // className={className('view-image', { isFullscreen, isModal })}
        className={`react-images__view-image ${
          isFullscreen ? 'react-images__view-image--isFullscreen' : ''
        } ${isModal ? 'react-images__view-image--isModal' : ''}`}
        controls="controls"
        style={{
          height: 'auto',
          maxHeight: '100%',
          maxWidth: '100%',
          userSelect: 'none'
        }}
      >
        <source
          src={src}
          // type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        />
        The video tag is not supported by your browser
        <a href={src}>Download video</a>.
      </video>
    </div>
  );
};

export default VideoView;
