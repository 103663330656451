import { createStore, createEvent, createEffect } from 'effector';

import api from 'api/tags';

const initialState = [];

export const loadTags = createEffect('Load tags').use(async () => {
  const { data } = await api.getTagsList();

  return data;
});

export const $tags = createStore(initialState)
  .on(loadTags.done, (state, { result }) => result)
  .on(loadTags.fail, (params, error) => {
    // console.error('Fetch tags error');
    throw error;
  });
