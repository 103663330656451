import React from 'react';
import VideoView from './VideoView';
import { carouselComponents } from 'react-images';

const { View: DefaultView } = carouselComponents;

const View = (props) => {
  const { data } = props;
  return data.video ? <VideoView {...props} /> : <DefaultView {...props} />;
};

export default View;
