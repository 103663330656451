/* eslint-disable react/no-danger */
import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';

const YANDEX_METRIKA_ACCOUNT = Number(
  process.env.REACT_APP_YANDEX_METRIKA_ACCOUNT
);

const YandexMetrika = () => {
  return YANDEX_METRIKA_ACCOUNT ? (
    <div>
      <YMInitializer
        accounts={[YANDEX_METRIKA_ACCOUNT]}
        options={{
          webvisor: true,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true
        }}
      />
    </div>
  ) : null;
};

export default YandexMetrika;
