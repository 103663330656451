import palette from './palette';

export default {
  fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  secondFontFamily: '"Noto Sans", "Roboto", "Helvetica", "Arial", sans-serif',
  h1: {
    // title 1
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '28px',
    fontStyle: 'normal',
    letterSpacing: '0.01em',
    lineHeight: '32px'
  },
  h2: {
    //title 2
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '22px',
    fontStyle: 'normal',
    letterSpacing: '0.01em',
    lineHeight: '27px'
  },
  h3: {
    //title 3
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '17px',
    fontStyle: 'normal',
    letterSpacing: '0.01em',
    lineHeight: '21px'
  },
  h4: {
    //title 4
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '-0.06px',
    lineHeight: '24px'
  },
  h5: {
    // title 5
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '0.01em',
    lineHeight: '20px'
  },
  h6: {
    //title 6
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.01em',
    lineHeight: '20px'
  },
  subtitle1: {
    // headlone 1
    fontFamily: '"Noto Sans"',
    color: palette.text.primary,
    fontWeight: 'normal',
    fontSize: '15px',
    fontStyle: 'normal',
    letterSpacing: '0.01em',
    lineHeight: '20px'
  },
  subtitle2: {
    // headline 2
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '15px',
    fontStyle: 'normal',
    letterSpacing: '0.01em',
    lineHeight: '20px'
  },
  body1: {
    fontFamily: '"Noto Sans"',
    color: palette.text.primary,
    fontWeight: 'normal',
    fontSize: '17px',
    fontStyle: 'normal',
    lineHeight: '22px'
  },
  body2: {
    fontFamily: '"Noto Sans"',
    color: palette.text.secondary,
    fontWeight: 'normal',
    fontSize: '15px',
    letterSpacing: '0.01em',
    lineHeight: '18px'
  },
  // footnote: {
  //   fontFamily: '"Noto Sans"',
  //   color: palette.text.secondary,
  //   fontWeight: 'normal',
  //   fontSize: '99px',
  //   fontStyle: 'normal',
  //   letterSpacing: '0.01em',
  //   lineHeight: '17px'
  // },
  button: {
    color: palette.text.primary,
    fontSize: '14px'
  },
  caption: {
    // caption
    color: palette.text.secondary,
    fontFamily: '"Noto Sans"',
    fontWeight: 'normal',
    fontSize: '12px',
    fontStyle: 'normal',
    // letterSpacing: '0.33px',
    lineHeight: '17px'
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    textTransform: 'uppercase'
  }
};
