import { createStore, createEvent } from 'effector';

const initialState = {
  locale: process.env.REACT_APP_USER_LANGUAGE,
  pathname: '/'
};

export const setLocale = createEvent('Set locale');
export const setPathname = createEvent('Set pathname');

export const $locale = createStore(initialState)
  .on(setLocale, (state, locale) => ({ ...state, locale }))
  .on(setPathname, (state, pathname) => ({ ...state, pathname }));
