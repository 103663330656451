import React from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'effector-react';
import { $locale } from 'models/locale';

const langs = {
  ru: 'Русский',
  en: 'English'
};

const getLocaleLink = (pathname, locale) => {
  if (pathname.match(/^\/([a-z]{2})$/)) {
    return pathname.replace(/^\/([a-z]{2})$/, `/${locale}`);
  } else if (pathname.match(/^\/([a-z]{2})\//)) {
    return pathname.replace(/^\/([a-z]{2})\//, `/${locale}/`);
  }
  return pathname;
};

const Locale = (props) => {
  const { locale, pathname } = useStore($locale);
  return (
    <div className="locale-switcher">
      {Object.entries(langs).map(([key, title]) =>
        locale === key ? (
          <span key={key}>{title}</span>
        ) : (
          <span key={key}>
            <Link to={getLocaleLink(pathname, key)}>{title}</Link>
          </span>
        )
      )}
    </div>
  );
};

export default Locale;
