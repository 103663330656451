/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { setLocation } from 'models/location';

const Geolocation = () => {
  useEffect(() => {
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (err) => {
          console.error(err);
        }
      );
    }

    return () => null;
  }, []);

  return null;
};

export default Geolocation;
