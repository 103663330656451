/* eslint-disable no-undef */
import React from 'react';
import { useStore } from 'effector-react';
import PropTypes from 'prop-types';
import { getLocaleLang } from 'i18n';
import { $tags } from 'models/tags';

const RequestTags = (props) => {
  const { tags } = props;

  const storeTags = useStore($tags);

  return (
    <div className="request-tags">
      {tags.map((tgId) => {
        const t = storeTags.find(({ _id }) => _id === tgId);

        return t !== undefined ? (
          <span key={t._id}>{t.title[getLocaleLang()] || t.title[0]}</span>
        ) : null;
      })}
      {/* <p className="request-textblock-title">{title}</p>
      <p className="request-textblock-description">
        {length ? `${description.slice(0, length).trim()}...` : description}
      </p> */}
    </div>
  );
};

RequestTags.propTypes = {
  tags: PropTypes.array.isRequired
};

export default RequestTags;
