import { getLocaleLang } from 'i18n';

// pages
export const getHomePage = () => '/app';

// volunteers
export const getVolunteerLink = (id) => `/${getLocaleLang()}/volunteer/${id}`;
export const getPolicyPageLink = () => `/${getLocaleLang()}/policy`;

export const getAppVolunteerLink = (id) => `getbackapp://volunteer/${id}`;

export const getAppleAppLink = () => process.env.REACT_APP_APPLE_STORE_LINK;
export const getGooglePlayAppLink = () =>
  process.env.REACT_APP_GOOGLE_PLAY_LINK;

export default {
  getVolunteerLink,
  getPolicyPageLink,
  getAppleAppLink,
  getGooglePlayAppLink,
  getAppVolunteerLink
};
