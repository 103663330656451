import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

const Volunteer = ({ route }) => {
  return (
    <main>
      <Suspense fallback={null}>{renderRoutes(route.routes)}</Suspense>
    </main>
  );
};

Volunteer.propTypes = {
  route: PropTypes.object
};

export default Volunteer;
