/* eslint-disable no-undef */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';
import View from './View';

const Gallery = (props) => {
  const { images = [], preview } = props;
  const [lightboxIsOpen, setLightBoxIsOpen] = useState(false);

  const onToggleLightbox = (e) => setLightBoxIsOpen(!lightboxIsOpen);

  return (
    <Fragment>
      {/* <Image
        // key={source.thumbnail}
        onClick={() => onToggleLightbox(0)}
      > */}
      <img onClick={() => onToggleLightbox(0)} src={preview} />
      {/* </Image> */}
      <ModalGateway>
        {lightboxIsOpen ? (
          <Modal onClose={onToggleLightbox}>
            <Carousel
              components={{ View: View }}
              currentIndex={0}
              // formatters={{ getAltText }}
              // frameProps={{ autoSize: 'width' }}
              views={images}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Fragment>
  );
};

Gallery.propTypes = {
  images: PropTypes.array.isRequired
};

export default Gallery;
