import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
export const resources = {
  en: {
    translation: {
      'Main title': 'GetBack',
      'Main description': `Getback is a Good Deeds App meant for the quick and convenient providing and getting assistance AT NO COST .Receive prompt assistance and  give help at any time and any place absolutely free of charge.
      You don\`t have to pay for the use of Getback. Just download the App and start communication. The application provides a great deal of filters for everyone to find whatever they need; easy-to- use map enables you to see all the requests and offers in real time. Create group chats and send messages, reposts and much more to  Direct.`,
      'find requests': 'find requests',
      About: 'About',
      Agreement: 'Agreement',
      'Privacy policy': 'Privacy policy',
      Promote: 'Promote',
      Report: 'Report',
      'Return to map': 'Return to map',
      Filter: 'Filter',
      clear: 'clear',
      'Donations only': 'Donations only',
      'intellectual tag': 'Intellectual volunteering',
      'physical tag': 'Physical assistance',
      'animals tag': 'Help animals',
      'material tag': 'Material help',
      'reasonable-consumption tag': 'Reasonable consumption',
      'information tag': 'Information',
      'more tag': 'More',
      'urgency tag': 'Urgency',
      Policy: 'Policy',
      Map: 'Map',
      FAQ: 'FAQ',
      'Bonus system': 'Bonus system',
      'Copy link': 'Копировать ссылку',
      'Link copied!': 'Ссылка скопирована!',
      Support: 'Support',
      'cookie notification description':
        'We use Cookies to ensure that we give you the best experience on our website. Read our',
      'cookie notification Privacy Policy': 'Privacy policy',
      'cookie notification button': 'I Agree',
      'volunteer page title': 'GetBack - Volunteer {{fullName}}',
      Share: 'Share',
      Print: 'Print',
      'Show code volunteer': 'Show code volunteer',
      Helped: 'Helped',
      Created: 'Created',
      'Member code': 'Member code {{id}}',
      years: 'years',
      'Open in app': 'Open in app',
      'Show on map': 'Show on map',
      'Similar requests': 'Similar requests'
    }
  },
  ru: {
    translation: {
      'Main title': 'GetBack',
      'Main description': `GetBack - приложение добрых дел,  для быстрого и удобного оказания и получения помощи на БЕЗВОЗМЕЗДНОЙ основе. Получайте оперативную помощь и оказывайте ее в любое время в любом месте совершенно бесплатно.
      Просите о помощи и отмечайте тех, кто помог. Делитесь впечатлениями об оказанной помощи !`,
      'find requests': 'найти просьбы',
      About: 'О проекте',
      Agreement: 'Пользовательское соглашение',
      'Privacy policy': 'Политика',
      Promote: 'Продвижение',
      Report: 'Обратная связь',
      'Return to map': 'Вернуться на карту',
      Filter: 'Фильтр',
      clear: 'очистить',
      'Donations only': 'Только пожертвования',
      'intellectual tag': 'Интеллектуальное волонтерство',
      'physical tag': 'Физическая помощь',
      'animals tag': 'Помощь животным',
      'material tag': 'Материальная помощь',
      'reasonable-consumption tag': 'Разумное потребление',
      'information tag': 'Информация',
      'more tag': 'Другое',
      'urgency tag': 'Срочно',
      // Policy: 'Соглашение',
      Map: 'Карта',
      FAQ: 'Вопросы',
      'Bonus system': 'Бонусная система',
      'Copy link': 'Копировать ссылку',
      'Link copied!': 'Ссылка скопирована!',
      Support: 'Поддержка',
      'cookie notification description':
        'Мы используем файлы cookie, чтобы обеспечить максимальное удобство использования нашего веб-сайта. Прочтите нашу',
      'cookie notification Privacy Policy': 'Политику Конфиденциальности',
      'cookie notification button': 'Я Cогласен',
      'volunteer page title': 'GetBack - Volunteer {{fullName}}',
      Share: 'Поделиться',
      Print: 'Распечатать',
      'Show code volunteer': 'Показать код участника',
      Helped: 'Помог',
      Created: 'Создал',
      'Member code': 'Код участника {{id}}',
      years: 'лет',
      'Open in app': 'Открыть в приложении',
      'Show on map': 'Показать на карте',
      'Similar requests': 'Похожие просьбы'
    }
  }
};

export const getLangs = () => Object.keys(resources);
export const getLocaleLink = (link = '') => `/${i18n.language}${link}`;
export const getLocaleLang = () => i18n.language;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: process.env.REACT_APP_USER_LANGUAGE || 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
