import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';
import { LinearProgress } from '@material-ui/core';

const Main = (props) => {
  const { route } = props;

  return (
    <main>
      <Suspense fallback={<LinearProgress />}>
        {renderRoutes(route.routes)}
      </Suspense>
    </main>
  );
};

Main.propTypes = {
  route: PropTypes.object
};

export default Main;
