/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy, useEffect, Suspense } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { useStore } from 'effector-react';
import { $locale, setLocale, setPathname } from 'models/locale';
import {
  isMobile,
  isIOS13,
  isIPhone13,
  isIPad13,
  isAndroid,
  isIOS
} from 'react-device-detect';
import InstallApp from 'views/InstallApp';
import ErrorLayout from './layouts/Error';
import MainLayout from './layouts/Main';
import VolunteerLayout from './layouts/Volunteer';
import PageLayout from './layouts/Page';
import i18n, { getLangs } from './i18n';
import GeoLocation from './components/GeoLocation';

const googlePlayLink = process.env.REACT_APP_GOOGLE_PLAY_LINK;
const appleStoreLink = process.env.REACT_APP_APPLE_STORE_LINK;

const storage = window.localStorage;

const langs = getLangs();

const LocalizedRouter = (props) => {
  const {
    location: { pathname },
    match: {
      params: { lang }
    },
    route
  } = props;

  useEffect(() => {
    if (!langs.includes(lang)) {
      //смотрим есть ли локаль если нету то редирект на 404
      return <Redirect to="/errors/error-404" />;
    }

    if (i18n.language !== lang) {
      // меняем локаль если она отличается от установленой
      i18n.changeLanguage(lang);
    }

    storage.setItem('locale', lang);
    setLocale(lang);
    setPathname(pathname);
  }, [pathname, lang]);

  return (
    <>
      <GeoLocation />
      {renderRoutes(route.routes)}
    </>
  );
};

const InitRouter = () => {
  const { locale } = useStore($locale);
  const lang = storage.getItem('locale') || locale;
  storage.setItem('locale', lang);
  return <Redirect to={`/${lang}`} />;
};

const RedirectAppRouter = (props) => {
  const {
    match: {
      params: { id },
      url
    },
    route
  } = props;

  // useEffect(() => {
  //   if (isMobile) {
  //     window.location = `getbackapp://request/${id}`;
  //     // setTimeout(function () {
  //     //   window.location = url;
  //     // }, 500);

  //     // window.open(`getbackapp://request/${id}`);
  //   }
  // });

  return renderRoutes(route.routes);
};

const UndefinedRedirect = () => {
  const { id } = useParams();
  const { locale } = useStore($locale);
  const lang = storage.getItem('locale') || locale;
  storage.setItem('locale', lang);

  return <Redirect to={`/${lang}/volunteer/${id}`} />;
};

const routes = [
  {
    path: '/',
    exact: true,
    key: 'root',
    component: () => <InitRouter />
  },
  {
    path: '/android',
    exact: true,
    key: 'ios',
    component: () => {
      window.location.href = googlePlayLink;
      return null;
    }
  },
  {
    path: '/ios',
    exact: true,
    key: 'ios',
    component: () => {
      window.location.href = appleStoreLink;
      return null;
    }
  },
  {
    path: '/install_app',
    exact: true,
    key: 'install app',
    component: () => {
      if (isMobile && (isIOS13 || isIPhone13 || isIPad13 || isIOS)) {
        setTimeout(() => {
          window.location.href = appleStoreLink;

          return null;
        }, 1000);
      } else if (isMobile && isAndroid) {
        setTimeout(() => {
          window.location.href = googlePlayLink;

          return null;
        }, 1000);
      }

      return <InstallApp />;
    }
  },
  {
    path: '/undefined/volunteer/:id',
    exact: true,
    component: UndefinedRedirect
  },
  {
    path: '/:lang([a-z]{2})',
    component: LocalizedRouter, // роутер для установки локализации
    routes: [
      {
        path: '/:lang([a-z]{2})',
        exact: true,
        component: MainLayout,
        key: 'main',
        routes: [
          {
            path: '/:lang([a-z]{2})',
            exact: true,
            component: lazy(() => import('views/Main'))
          }
        ]
      },
      {
        path: '/:lang([a-z]{2})/volunteer/:id',
        exact: true,
        component: VolunteerLayout,
        key: 'main',
        routes: [
          {
            path: '/:lang([a-z]{2})/volunteer/:id',
            exact: true,
            component: lazy(() => import('views/Volunteer'))
          }
        ]
      },
      {
        route: '/:lang([a-z]{2})',
        component: PageLayout,
        key: 'page',
        routes: [
          {
            path: '/:lang([a-z]{2})/request/:id',
            exact: true,
            component: RedirectAppRouter,
            routes: [
              {
                path: '/:lang([a-z]{2})/request/:id',
                exact: true,
                component: lazy(() => import('views/Detail'))
              }
            ]
          },
          {
            path: '/ru/about',
            exact: true,
            key: 'about',
            component: lazy(() => import('views/About/AboutRU'))
          },
          {
            path: '/:lang([a-z]{2})/about',
            exact: true,
            key: 'about',
            component: lazy(() => import('views/About/AboutEN'))
          },
          {
            path: '/ru/agreement',
            exact: true,
            key: 'agreement ru',
            component: lazy(() => import('views/Agreement/AgreementRU'))
          },
          {
            path: '/:lang([a-z]{2})/agreement',
            exact: true,
            key: 'agreement',
            component: lazy(() => import('views/Agreement/AgreementEN'))
          },
          {
            path: '/ru/faq',
            exact: true,
            key: 'faq ru',
            component: lazy(() => import('views/FAQ/FaqRU'))
          },
          {
            path: '/:lang([a-z]{2})/faq',
            exact: true,
            key: 'faq',
            component: lazy(() => import('views/FAQ/FaqEN'))
          },
          {
            path: '/ru/policy',
            exact: true,
            key: 'policy ru',
            component: lazy(() => import('views/Policy/PolicyRU'))
          },
          {
            path: '/:lang([a-z]{2})/policy',
            exact: true,
            key: 'policy',
            component: lazy(() => import('views/Policy/PolicyEN'))
          },
          {
            path: '/:lang([a-z]{2})/promote',
            exact: true,
            key: 'promote',
            component: lazy(() => import('views/About/AboutEN'))
          },
          {
            path: '/:lang([a-z]{2})/report',
            exact: true,
            key: 'promote',
            component: lazy(() => import('views/About/AboutEN'))
          },
          {
            path: '/ru/support',
            exact: true,
            key: 'support ru',
            component: lazy(() => import('views/Support/SupportRU'))
          },
          {
            path: '/:lang([a-z]{2})/support',
            exact: true,
            key: 'support en',
            component: lazy(() => import('views/Support/SupportEN'))
          },
          {
            path: '/ru/bonus_point_system',
            exact: true,
            key: 'bonus ru',
            component: lazy(() => import('views/Bonus/BonusRU'))
          },
          {
            path: '/:lang([a-z]{2})/bonus_point_system',
            exact: true,
            key: 'bonus en',
            component: lazy(() => import('views/Bonus/BonusEN'))
          },
          {
            component: () => <Redirect to="/errors/error-404" />
          }
        ]
      },

      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    key: 'errors',
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        key: 'error-401',
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        key: 'error-404',
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        key: 'error-500',
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    component: () => <Redirect to="/errors/error-404" />
  }
];

export default routes;
